var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "", "fill-height": "", "fill-width": "" } },
    [
      _c("v-row", { attrs: { justify: "center" } }, [
        _c(
          "form",
          {
            attrs: { id: "adm-import-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
                return _setup.startImport()
              },
            },
          },
          [
            _c(
              "v-card",
              [
                _c("v-card-title", { staticClass: "headline" }, [
                  _c("span", { staticClass: "headline" }, [
                    _c("h1", [_vm._v("Importeren")]),
                  ]),
                ]),
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-layout",
                      [
                        _c(
                          "v-container",
                          { staticClass: "register-form" },
                          [
                            _c(
                              "v-row",
                              [
                                _c("v-col", [
                                  _c("h3", { staticClass: "large" }, [
                                    _vm._v("Kies importbestand"),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c("vue-dropzone", {
                                      ref: "dropZone",
                                      attrs: {
                                        id: "file-dropzone",
                                        options: {
                                          url: _setup.uploadUrl,
                                          acceptedFiles: ".xls,.xlsx",
                                          maxFilesize: 10000000,
                                          maxFiles: 1,
                                          thumbnailWidth: 150,
                                          thumbnailHeight: 150,
                                          addRemoveLinks: true,
                                          autoProcessQueue: false,
                                          dictDefaultMessage:
                                            "Sleep uw bestand hier naar toe of klik om te uploaden. Toegestane formaten: .xls, .xlsx",
                                        },
                                      },
                                      on: {
                                        "vdropzone-success": _setup.onUploaded,
                                        "vdropzone-error": _setup.onUploadError,
                                        "vdropzone-file-added":
                                          _setup.onFileAdded,
                                        "vdropzone-sending": _setup.onSend,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-row",
                              [
                                _c(
                                  "v-col",
                                  [
                                    _c(
                                      "v-radio-group",
                                      {
                                        model: {
                                          value: _setup.importType,
                                          callback: function ($$v) {
                                            _setup.importType = $$v
                                          },
                                          expression: "importType",
                                        },
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: {
                                            label: "innaxonlinemaatregelen",
                                            value: "innaxonlinemaatregelen",
                                          },
                                        }),
                                        _c("v-radio", {
                                          attrs: {
                                            label: "party",
                                            value: "party",
                                          },
                                        }),
                                        _c("v-radio", {
                                          attrs: {
                                            label: "usages",
                                            value: "usages",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  [
                                    _c("v-checkbox", {
                                      attrs: { label: "checkEnums" },
                                      model: {
                                        value: _setup.checkEnums,
                                        callback: function ($$v) {
                                          _setup.checkEnums = $$v
                                        },
                                        expression: "checkEnums",
                                      },
                                    }),
                                    _setup.importType ==
                                    "innaxonlinemaatregelen"
                                      ? _c("v-text-field", {
                                          attrs: { label: "Party" },
                                          model: {
                                            value: _setup.partyName,
                                            callback: function ($$v) {
                                              _setup.partyName = $$v
                                            },
                                            expression: "partyName",
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _setup.errors != null && _setup.errors != ""
                              ? _c("v-row", [
                                  _c("pre", [_vm._v(_vm._s(_setup.errors))]),
                                ])
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "v-card-actions",
                  [
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        staticClass: "primary",
                        staticStyle: { "margin-right": "20px" },
                        attrs: {
                          dark: "",
                          type: "submit",
                          title: "Opslaan",
                          disabled: !_setup.ready,
                        },
                      },
                      [
                        _c("v-icon", { attrs: { dark: "", left: "" } }, [
                          _vm._v("mdi-checkbox-marked-circle"),
                        ]),
                        _vm._v(" Importeren "),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }